import { clsx } from 'clsx';
import Link, { type LinkProps } from 'next/link';
import type { PropsWithChildren } from 'react';

interface LinkButtonProps extends LinkProps {
  onClick?: (event: React.MouseEvent) => void;
  variant?: 'purple' | 'blue';
  className?: string;
}

const variants = {
  purple: {
    container: 'hover:bg-gradient-to-r hover:from-purple-accent-1/10 hover:to-blue-accent-1/10',
    text: 'text-link hover:bg-gradient-to-r hover:from-purple-primary hover:to-blue-primary hover:bg-clip-text hover:text-transparent',
  },
  blue: { container: 'hover:bg-blue-glass', text: 'text-link' },
};

export const LinkButton = ({
  href,
  onClick,
  variant = 'blue',
  className,
  children,
  ...props
}: PropsWithChildren<LinkButtonProps>) => {
  return (
    <Link
      href={href ?? undefined}
      onClick={onClick}
      {...props}
      className={clsx('rounded-md px-1 pl-1.5 transition-colors', variants[variant].container)}
    >
      <div className={clsx('text-regular-plus', variants[variant].text, className)}>{children}</div>
    </Link>
  );
};
