import { z } from 'zod';

export enum VerifyOtpFailureReason {
  InvalidCode = 'invalidCode',
  NotFound = 'notFound',
}

export const verifyOtpRequestSchema = z.object({
  phone: z.string(), // TODO: In future we'll take a session ID instead since that's much more secure.
  code: z.string(),
});

export type VerifyOtpResponseData =
  | {
      success: false;
      reason?: VerifyOtpFailureReason;
      detail: string;
    }
  | {
      success: true;
      id: string;
    };
