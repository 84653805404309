import { Turnstile, type TurnstileInstance, type TurnstileProps } from '@marsidev/react-turnstile';
import { useTheme } from 'next-themes';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form/dist/types/form';
import { type z } from 'zod';

import { useStableCallback } from '@/client/core/hooks/use-stable-callback';
import { TURNSTILE_SITE_KEY, TurnstileError, turnstileFetch } from '@/client/core/utils/turnstile';
import { Button } from '@/client/design-system/components/v2/button/button';
import { ResendButton } from '@/client/design-system/components/v2/button/resend-button';
import { Input } from '@/client/design-system/components/v2/fields/input';
import { PinInput } from '@/client/design-system/components/v2/fields/pin-input';
import { LinkButton } from '@/client/design-system/components/v2/link/link-button';
import { useAnalytics } from '@/client/features/analytics';
import { formatPhone, tryGetValidPhone, validateValidPhone } from '@/client/features/auth/phone';
import { useAuthContext } from '@/client/features/auth/providers/auth-provider';
import { useDialog } from '@/client/features/dialog';
import type { LoginRequestData, LoginResponseData } from '@/client/features/login/api/login';
import { LoginUnauthorizedReason } from '@/client/features/login/api/login';
import type { verifyOtpRequestSchema, VerifyOtpResponseData } from '@/client/features/login/api/otp/verify';
import { VerifyOtpFailureReason } from '@/client/features/login/api/otp/verify';
import { OAuthErrorToasts } from '@/client/features/oauth/components/oauth-error-toasts';
import { useToast } from '@/client/features/toast/providers/toast-provider';
import { BannedModal } from '@/client/features/user/components/banned-modal/banned-modal';
import { useRouter } from '@/hooks/use-compatible-router';
import { ROUTES } from '@/legacy/lib/constants';
import { parsePhoneWithFallbackToUS, validateEmail } from '@/legacy/lib/helpers';

type TurnstileSize = NonNullable<NonNullable<TurnstileProps['options']>['size']>;

type FormValues = {
  code: string;
  contact: string;
};

// This is not 100% reliable since it is possible, albeit unlikely, to have an email starting with 3+ digits.
const isPhone = (contact: string) => {
  return !contact.includes('@') && contact.replace(/[()]/, '').match(/^(?:\+|[0-9]{3})/);
};

const tryGetValidEmailOrPhone = (contact: string) => {
  return isPhone(contact) ? tryGetValidPhone(contact) : contact;
};

const validateValidEmailOrPhone = (contact: string) => {
  return contact.includes('@')
    ? validateEmail(contact)
      ? true
      : 'Please enter a valid email address'
    : validateValidPhone(contact);
};

export const login = async ({
  credentials,
  turnstileToken,
}: {
  credentials: LoginRequestData;
  turnstileToken: string;
}): Promise<LoginResponseData> => {
  try {
    const res = await turnstileFetch<LoginRequestData>('/api/login', {
      method: 'POST',
      body: credentials,
      turnstile: turnstileToken,
      redirect: 'manual',
    });

    return await res.json();
  } catch (e) {
    if (e instanceof TurnstileError) {
      throw e;
    }

    return {
      authorized: false,
      detail: 'Network connectivity issue.',
    };
  }
};

export const verifyOtp = async ({
  code,
  phone,
  turnstileToken,
}: {
  code: string;
  phone: string;
  turnstileToken: string;
}): Promise<VerifyOtpResponseData> => {
  try {
    const res = await turnstileFetch<z.infer<typeof verifyOtpRequestSchema>>('/api/otp/verify', {
      method: 'POST',
      body: {
        phone,
        code,
      },
      turnstile: turnstileToken,
    });

    return await res.json();
  } catch (e) {
    if (e instanceof TurnstileError) {
      throw e;
    }

    return {
      success: false,
      detail: 'Network connectivity issue.',
    };
  }
};

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  closeModalCallback?: (success: boolean) => void;
  confirmationRequest?: boolean;
  handleLinkClick?: (e: any, modal: string) => void;
}

const LoginForm = ({ closeModalCallback, confirmationRequest, handleLinkClick, ...props }: Props) => {
  const submissionAwaitingTurnstile = useRef(false);

  const {
    control,
    formState: { errors, isValid, isSubmitting, isSubmitted },
    handleSubmit,
    register,
    setValue,
    setError,
    unregister,
    watch,
  } = useForm<FormValues>();

  const router = useRouter();
  const { showDialog } = useDialog();
  const { showSimpleToast } = useToast();
  const { identifyUser, trackEvent } = useAnalytics();
  const { redirect, refetch } = useAuthContext();
  const { reset_success: passwordReset = null } = router.query;
  const { theme } = useTheme();
  const currentTheme = theme === 'light' ? 'light' : theme === 'dark' ? 'dark' : 'auto';

  const [showCodeInput, setShowCodeInput] = useState(false);
  const [resent, setResent] = useState(false);

  const [turnstileSize, setTurnstileSize] = useState<TurnstileSize>('invisible');
  const [turnstileToken, setTurnstileToken] = useState<string>();
  const turnstile = useRef<TurnstileInstance>();

  const resetTurnstile = useCallback(() => {
    setTurnstileToken(undefined);
    turnstile.current?.reset();
  }, []);

  const triggerLoginEvents = useCallback(
    async (userId: string) => {
      // User authenticated.
      await identifyUser({
        object: 'user',
        action: 'loggedIn',
        userId,
      });
    },
    [identifyUser]
  );

  useEffect(() => {
    void router.prefetch(redirect ?? '/');
  }, [redirect, router]);

  const onLoggedIn = useCallback(
    async (userId: string) => {
      void triggerLoginEvents(userId);

      await refetch();

      if (closeModalCallback) {
        closeModalCallback(true);
      } else {
        await router.push(redirect ?? '/');
      }
    },
    [closeModalCallback, redirect, refetch, router, triggerLoginEvents]
  );

  const submitLogin = useStableCallback(async (contact: string, turnstileToken: string) => {
    void trackEvent({
      object: 'userLoginForm',
      action: 'submitted',
      properties: {
        channel: 'email',
      },
    });

    const credentials = contact.includes('@') ? { email: contact } : { phone: contact };
    const response = await login({ credentials, turnstileToken });

    if (response.authorized) {
      await onLoggedIn(response.id);
    } else if (response.reason === LoginUnauthorizedReason.OtpSent) {
      if (!resent) {
        setResent(true);
        setTimeout(() => {
          setResent(false);
        }, 3000);
      }

      showSimpleToast({
        title: response.detail,
        color: 'success',
      });

      if (credentials.phone) {
        setValue('contact', contact);
        setShowCodeInput(true);
      }
    } else if (response.reason === LoginUnauthorizedReason.Redirect) {
      await router.push(response.detail);
    } else {
      resetTurnstile();

      if (response.reason === LoginUnauthorizedReason.NotFound) {
        const contactType = contact.includes('@') ? 'email address' : 'phone number';
        setError('contact', { message: `No account found for this ${contactType}.`, type: 'validate' });
      } else if (response.reason === LoginUnauthorizedReason.Banned) {
        showDialog({
          dialog: <BannedModal reason={response.detail} />,
        });
      } else {
        showSimpleToast({
          title: 'Sign in failed',
          description: response.detail,
          color: 'danger',
        });
      }
    }
  });

  const submitCode = useStableCallback(async (code: string, turnstileToken: string) => {
    const response = await verifyOtp({ code, phone: contact, turnstileToken });

    if (response.success) {
      await onLoggedIn(response.id);
    } else {
      resetTurnstile();

      if (response.reason === VerifyOtpFailureReason.InvalidCode) {
        setError('code', { message: "The code you entered doesn't match.", type: 'validate' });
        setValue('code', '');
      }

      showSimpleToast({
        title: 'Sign in failed',
        description: response.detail,
        color: 'danger',
      });
    }
  });

  const onSubmit: SubmitHandler<FormValues> = useStableCallback(async (data) => {
    if (!turnstileToken) {
      if (!showCodeInput) {
        // Turnstile is not yet ready, show Turnstile so users know what they're waiting on.
        setTurnstileSize('normal');
      }

      // When Turnstile completes, will automatically submit again.
      submissionAwaitingTurnstile.current = true;
      return;
    }

    const retryUponTurnstileFailure = !submissionAwaitingTurnstile.current;
    submissionAwaitingTurnstile.current = false;
    setTurnstileSize('invisible');

    const { code, contact } = data;

    try {
      if (code) {
        await submitCode(code, turnstileToken);
      } else {
        await submitLogin(contact, turnstileToken);
      }
    } catch (error: unknown) {
      resetTurnstile();

      if (error instanceof Error) {
        if (retryUponTurnstileFailure && error instanceof TurnstileError) {
          submissionAwaitingTurnstile.current = true;
          return;
        }

        showSimpleToast({
          title: error.message || 'An unexpected error occurred.',
          description: 'Please try again.',
          color: 'danger',
        });

        console.error('An unexpected error occurred:', error);
      }
    }
  });

  const onResend = useCallback(() => {
    unregister('code');
    void handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit, unregister]);

  useEffect(() => {
    if (passwordReset) {
      showSimpleToast({
        title: 'Password successfully reset',
        description: 'Sign in to continue.',
        color: 'success',
      });
    }
  }, [passwordReset, showSimpleToast]);

  useEffect(() => {
    if (!isSubmitted || showCodeInput || !turnstileToken) {
      return;
    }

    if (submissionAwaitingTurnstile.current) {
      void handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isSubmitted, onSubmit, showCodeInput, turnstileToken]);

  useEffect(() => {
    if (!showCodeInput) {
      unregister('code');
    }
  }, [showCodeInput, unregister]);

  const contact = watch('contact', '');
  const code = watch('code', '');

  useEffect(() => {
    if (!isPhone(contact)) {
      setShowCodeInput(false);
    }
  }, [contact, setValue]);

  useEffect(() => {
    if (code.length === 4) {
      void handleSubmit(onSubmit)();
    }
  }, [code, handleSubmit, onSubmit]);

  const turnstileVisible = turnstileSize !== 'invisible';

  return (
    <div {...props} className='flex w-full max-w-[360px] flex-col gap-4'>
      <OAuthErrorToasts />
      <div className='flex flex-col gap-2 text-center'>
        <h1 className='text-title3 font-semibold text-primary' data-cy='loginTitle'>
          {confirmationRequest ? 'Parent confirmation required' : 'Login to your Ender account'}
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className='flex w-full flex-col gap-4'>
        <Input
          disabled={isSubmitting}
          id='contact'
          label='Phone or email'
          placeholder='(123) 456-7890 / name@email.com'
          register={register('contact', {
            required: 'Required',
            setValueAs: tryGetValidEmailOrPhone,
            validate: validateValidEmailOrPhone,
            onChange: (event) => {
              const value = event.target.value;

              if (!value || typeof value !== 'string' || !isPhone(contact)) {
                return;
              }

              if (value.startsWith('+')) {
                event.target.value = parsePhoneWithFallbackToUS(value);
              } else {
                event.target.value = formatPhone(value, contact);
              }
            },
          })}
          control={control}
          setValue={setValue}
          type='text'
          inputMode='email'
          error={errors.contact?.message}
          success={isValid || showCodeInput}
          autoFocus
        />

        {showCodeInput && (
          <PinInput
            id='code'
            label='Enter 4-digit code'
            placeholder='-'
            resendButton={
              <ResendButton
                loading={isSubmitting}
                disabled={isSubmitting || resent}
                success={resent}
                onClick={onResend}
              />
            }
            register={register('code', {
              required: 'Required',
              minLength: {
                value: 4,
                message: 'Required',
              },
              maxLength: {
                value: 4,
                message: 'Required',
              },
            })}
            control={control}
            setValue={setValue}
            watch={watch}
            error={errors.code?.message}
            autoFocus
          />
        )}

        <div className='flex flex-col'>
          <Turnstile
            className={turnstileVisible ? 'mx-auto mb-4' : ''}
            onError={() => {
              /* Swallow the error */
            }}
            onExpire={() => {
              setTurnstileToken(undefined);
            }}
            onSuccess={setTurnstileToken}
            options={{
              size: turnstileSize,
              theme: currentTheme,
            }}
            ref={turnstile}
            siteKey={TURNSTILE_SITE_KEY}
          />

          <Button className='h-[40px]' loading={isSubmitting}>
            {showCodeInput ? (confirmationRequest ? 'Confirm' : 'Log in →') : 'Send confirmation'}
          </Button>
        </div>
      </form>

      {!confirmationRequest && (
        <div className='flex items-center justify-center gap-1'>
          <p className='text-regular-plus text-secondary'>Not signed up?</p>
          <LinkButton variant='purple' href={`${ROUTES.signup}?coupon_code=cxzQPsKI&variant=single`}>
            Create an account →
          </LinkButton>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
