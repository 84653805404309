import { clsx } from 'clsx';
import React, { useEffect } from 'react';

import { withClubStaticProps } from '@/api/clubs/page-props';
import { Logo } from '@/client/design-system/brand';
import { AnalyticsSource, useAnalyticsPageView } from '@/client/features/analytics';
import { TrackingSource } from '@/client/features/analytics/events/tracking-events';
import { AuthProvider } from '@/client/features/auth/providers/auth-provider';
import { useIntercom } from '@/client/features/intercom/use-intercom';
import LoginForm from '@/client/features/signin/components/login-form/login-form';
import Meta from '@/legacy/components/meta';
import { ROUTES } from '@/legacy/lib/constants';

import styles from './index.module.css';

export const getStaticProps = withClubStaticProps();

const Signin = () => {
  const { enableIntercom } = useIntercom();

  useEffect(() => {
    return enableIntercom();
  }, [enableIntercom]);

  useAnalyticsPageView({ page: 'login' });

  const pageTitle = 'Ender / Sign In';

  return (
    <AnalyticsSource value={TrackingSource.signup}>
      <AuthProvider mobileDisplay={false} redirectTo={ROUTES.root} pageQueryParams={{}}>
        <Meta tabTitle={pageTitle} pageTitle={pageTitle} />
        <div className={styles.pageContainer}>
          <nav className={clsx('flex w-full items-center p-5 justify-center')}>
            <Logo fill='black' redirect='website' />
          </nav>
          <div className={styles.loginContainer}>
            <LoginForm />
          </div>
        </div>
      </AuthProvider>
    </AnalyticsSource>
  );
};

Signin.navigation = false;
Signin.theme = 'light';
export default Signin;
