import { z } from 'zod';

export enum LoginUnauthorizedReason {
  Banned = 'banned',
  Disabled = 'disabled',
  InvalidCredentials = 'invalidCredentials',
  NoPassword = 'noPassword',
  NotFound = 'notFound',
  OtpRateLimited = 'otpRateLimited',
  OtpSendingFailed = 'otpSendingFailed',
  OtpSent = 'otpSent',
  Redirect = 'redirect',
}

export const userIdentifierSchema = z.union([
  z.object({ email: z.string().min(1) }),
  z.object({ phone: z.string().min(1) }),
]);

export const loginRequestSchema = userIdentifierSchema;

export type LoginRequestData = z.infer<typeof loginRequestSchema>;

export type LoginResponseData =
  | {
      authorized: false;
      reason?: LoginUnauthorizedReason;
      detail: string;
    }
  | {
      authorized: true;
      id: string;
    };
